import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import objectFitImages from "object-fit-images";
// helpers
import {
  changeDropoffMethodBackwardsCompatability,
  getExpressCodeFromURL,
  handleEnterKeyPress,
  handleEnterKeyPressOnClickHandlers,
  jwtClaimsByRuntime,
  mapURLQueryStringToObject,
  openSwiftCameraScanner
} from "../utility";
import useSelector from "../utility/useTypedSelector";
import { isEmpty } from "lodash";
import Success from "../pages/Success";
import Preview from "../pages/Preview";
import ReturnComplete from "../pages/ReturnComplete";
import TypeExpressCode from "../pages/TypeExpressCode";
import StartPartner from "../pages/StartPartner";
import SignOut from "../pages/SignOut";
import ReturnType from "../pages/ReturnType";
import OrderSearch from "../pages/OrderSearch";
import OrderList from "../pages/OrderList";
import ReturnReasons from "../pages/ReturnReasons";
import ReasonNotes from "../pages/ReasonNotes";
import ReturnOptions from "../pages/ReturnOptions";
import NoReturnOptions from "../pages/NoReturnOptions";
import Exchange from "../pages/Exchange";
import ConfirmationWithoutLogistics from "../pages/ConfirmationWithoutLogistics";
import MissingReturnEmail from "../pages/MissingReturnEmail";
import { FinalError, FinalWarning } from "../components/Final";
import { LoadingIndicator } from "../components/LoadingIndicator";
import AlertModal from "../components/Modal/AlertModal";
import PendingChangesModal from "../components/Modal/PendingChangesModal";
import StartReturnPortal from "../pages/StartReturnPortal";
import TimeoutModal from "../components/Modal/TimeoutModal";
import TypeStoreNumber from "../pages/TypeStoreNumber";
import ExpiredReturn from "../pages/ExpiredReturn";
import ReturnStatus from "../pages/ReturnStatus";

import {
  clearAlert,
  getConfigurationAndInitialize,
  goToPage,
  handleReturnistaExpiredToken,
  onFatalError,
  refreshToken,
  reset as appReset,
  setLocalErrorMessage,
  setQueries,
  setReturnPortalTimeout,
  setToken,
  showAlert,
} from "../redux/app/actions";
import { reset as customerReset, resetItemsMarkedForCompletion, } from "../redux/customer/actions";
import { SVG } from "../components/svg";
import {
  $AdminModeBadge,
  $Header,
  $HeaderLeft,
  $HeaderLeftArrow,
  $HeaderLeftText,
  $HeaderLogo,
  $HeaderRight,
  $HeaderRightArrow,
  $HeaderRightText,
  $HeaderTitle,
  $MainContent
} from "./styles";
import { Alerts, FatalErrors } from "../types/LifeCycle";
import { Page } from "../types/Config";
import { PartnerScanBagsPageConfiguration as PSBPC, ReturnPortalLoginConfiguration as RPLC } from "../pages/types";
import LoginReturnPortal from "../pages/LoginReturnPortal";
import DropoffMethods from "../pages/DropoffMethods";
import ReturnStarted from "../pages/ReturnStarted";
import { createGlobalStyle } from "styled-components";
import RetailerLogo from "../components/RetailerLogo";
import { isIOS } from "react-device-detect";
import Uninstalled from "../pages/Uninstalled";
import RefundOptions from "../pages/RefundOptions";
import ShippingLabelRequestForm from "../pages/ShippingLabelRequestForm";
import ShippingLabelSuccessPage from "../pages/ShippingLabelSuccessPage";
import i18n, { defaultLocale, detectLocale } from "../i18n"
import getTranslator from "../utility/getTranslator";
import ga from "../utility/GAEmitter";
import logger from "../utility/logger/logger";
import {
  AnalyticCategories,
  CommonPageActions,
  getCategoryFromPageOrModalName
} from "../types/Analytics";
import { DataCyStrings } from "../types/DataCyStrings";
import { AppRuntimes } from "../types/AppRuntimes";
import ReturnReasonsChildren from "../pages/ReturnReasonsChildren";
import { ReturnistaJWTClaims } from "../types/JWTClaims";

const useTranslation = getTranslator("App")

const detectedLocale: string = detectLocale()

const App = props => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  //-----------------------------------------------------------------------

  const {
    currentPageName,
    currentModalPageName,
    pages,
    scanPath,
    confirmationPath,
    fatalErrorName,
    returnPortalTimeout,
    loadingMessage,
    isInitialized,
    alertName,
    token,
    runtime,
    queries,
    colors,
    copies,
    links,
    disableHeaderLinks,
    locale,
    platform
  } = useSelector((store) => store.app);

  const { returns, itemsMarkedForCompletion } = useSelector((store) => store.customer);

  const claims = (runtime && token) ? jwtClaimsByRuntime[runtime]?.(token) : {
    returnsApp: {}
  }

  const retailerName = copies?.retailerName
  const retailerLogoAltText = retailerName ? t('retailerNameLogo', {retailerName}) : t('retailerLogo')

  // admin mode is set by the Retailer Dashboard to provide admin overrides in the Return Portal
  const isAdminMode = Boolean(claims?.returnsApp?.adminMode);

  // if the locationID belongs to retailer, this field
  // is populated in the JWT claims. When this field is defined,
  // allow the user to skip when using the swift camera scanner
  const allowSkip = Boolean(claims?.returnsApp?.retailerID);

  // after maxSecondsOfInactivity seconds, we consider the user inactive
  const maxSecondsOfInactivity = 60 * 15;

  // TODO - as more returns-app variants are created, we need a way to enforce this
  // in a graceful way
  const resetAppDataOnInitialize = runtime != AppRuntimes.returnista;

  const expressCode = returns?.confirmationCode;

  const [showPendingChangesModal, setShowPendingChangesModal] = useState(false);

  const pendingChangesModalCallbacks = {
    ios: {
      orderList: {
        discardChanges: () => {
          setShowPendingChangesModal(false);
          dispatch(goToPage("orderSearch"));
        },
        keepChanges: () => {
          setShowPendingChangesModal(false);
        },
        shouldRender: () => {
          return itemsMarkedForCompletion.length > 0;
        }
      }
    }
  }
  //----------------------------------------------------------------------------
  // HACKS
  //
  // minor grossness -- in order to call endpoints we need our token to always
  // be in the header.  the process of initialization does this... however if we
  // refresh the page and bypass initialization (as we do according to spec because
  // we want a bunch of the store to persist) then axios clears the header
  // so.... i have this hacky line here to establish the header in such cases
  if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
  } else {
    // Do nothing
    // Do not add token if the token is absent for CredentialsOptional case
  }
  //
  // IE likes to cache http calls for no reason. let's try to turn that off!
  // https://medium.com/@samichkhachkhi/ie-11-caching-axios-http-calls-e7f6474b11a5
  axios.defaults.headers.common['Pragma'] = "no-cache";

  // XXX - safari mobile creates extra spacing for the url/status bars as well as the footer,
  // so we use this JS workaround to force the page to fill the screen instead
  const handleIOSHeight = () => {
    if (!isIOS) {
      return;
    }

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight);
    appHeight();
  }

  const handleReturnistaTokenExpiration = () => {
    // when the user's token expires in the ios (returnista) runtime, we need to make
    // sure they're sent back to login and to discard the token fron the store
    const expiredTokenMessage = t('yourSessionHasExpired');
    if (runtime === AppRuntimes.returnista) {
      axios.interceptors.response.use(function (response) {
        return response;
      }, function (error) {
        if (error.response.status == 401) {
          dispatch(handleReturnistaExpiredToken(expiredTokenMessage));
          // we've already caught the error and handled it gracefully
          // so we need to ensure that the response isn't handled
          // by any other catch block, so we resolve it here.
          return Promise.reject(error);
        } else {
          // any other error will be handled by the component that sends the request
          return Promise.reject(error);
        }
      });
    }
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPER FUNCTIONS

  // we don't want to block the user from using the client due to inactivity.
  // This function is used to redirect the user to the home page instead,
  // which then resets the customer store
  const handleInactiveReturnistaUser = () => {
    expressCode && dispatch(setLocalErrorMessage(t('expressReturnExpressCode', {expressCode})));
    dispatch(goToPage("home"));
  }

  // This function is used to prompt the user to be able to navigate to the
  // login page to log in again, as well as resetting the customer store
  // and token
  const handleInactiveReturnPortalUser = () => {
    dispatch(setReturnPortalTimeout(true));
    dispatch(customerReset());
    dispatch(setToken(""));
  }

  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HOOKS
  //
  // on launch, get configuration and do other initialization
  useEffect(() => {
    changeDropoffMethodBackwardsCompatability();
    const urlExpressCode = getExpressCodeFromURL();
    const urlQueries = mapURLQueryStringToObject();
    const expressCodeIsNew = (urlExpressCode && (urlExpressCode != expressCode));

    // as the returns-app starts to expand to channels outside of fedex,
    // I think it would be a good idea to switch the user to "channel:expressCode"
    if (urlExpressCode) {
      logger.SetGlobalContextProperty("urlExpressCode", { urlExpressCode });
    }

    let queriesMatch = true;
    if (isEmpty(queries)) {
      // nothing to compare so we force the redux app store queries to reset
      queriesMatch = false;
    } else {
      Object.keys(urlQueries).forEach((key) => {
        if (queriesMatch && urlQueries[key] != queries[key]) {
          queriesMatch = false;
        }
      });
    }

    // flush local error on refresh
    dispatch(setLocalErrorMessage(""));


    if (!isInitialized || expressCodeIsNew || !queriesMatch) {
      // reset both of our slices to avoid bad state due to old data
      resetAppDataOnInitialize && dispatch(appReset());

      // override locale with url query if present.
      dispatch(getConfigurationAndInitialize(detectedLocale));
      dispatch(setQueries(urlQueries));

    } else if (!queriesMatch) {

    }

    // RETURNISTA RUNTIME INITIALIZATIONS
    handleIOSHeight();
    handleReturnistaTokenExpiration();
  }, []);

  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    })
  }, [])
  useEffect(() => {
    if (i18n.language != locale)
      i18n.changeLanguage(locale);

    // for ADA compliance and browser continuity
    document.documentElement.lang = locale || defaultLocale;
    // set page title according to retailer and language
    if (retailerName)
      document.title = t('returnPortalTitle', {
        retailerName,
        defaultValue: "{{retailerName}} Returns"
      });
  }, [locale, retailerName]);


  // binding the timer to the currentPageName ensures that we always have the
  // page bounded by the current session in memory and not the last page in localstorage when
  // as having 2 tabs interacting with one instance leads to conflicts
  useEffect(() => {
    // tracks how many seconds it's been since the user moved their mouse
    let inactivityThreshold = (Date.now()/1000) + maxSecondsOfInactivity;
    const resetInactivityThreshold = () => { inactivityThreshold = (Date.now()/1000) + maxSecondsOfInactivity };

    // set up timer and listeners to automatically log the user out after a period of inactivity
    const intervalID = setInterval(() => {
      // Check for expired JWT or inactivity timer and send the user back to the login (or fatal error) page
      // based on runtime
      // Date.now() returns a unix timestamp with ms - the claims exp is unic timestamp without ms
      const now = Math.floor(Date.now() / 1000);

      // when the timeout is met, we don't care if the user is still inactive so only
      // trigger the event once as the timer resets when the user moves their mouse
      if (now >= inactivityThreshold) {
        resetInactivityThreshold();

        let timedOutPage = "";

        // on the scan bags page, we want to show a modal instead of timing the user out
        switch (currentPageName) {
          case "scanBags":
          case "partnerConfirmation":
            // on the scan bags page, we want to show a modal instead of timing the user out
            if (alertName != Alerts.userInactive) {
              timedOutPage = currentPageName;
              dispatch(showAlert(Alerts.userInactive))
            }
            break;
          case "preview":
          case "instanceList":
          case "typeExpressCode":
          case "scanExpressCode":
          case "orderList":
          case "returnReasons":
          case "returnReasonsChildren":
          case "returnOptions":
          case "exchange":
          case "dropoffMethods":
          case "returnStarted":
          case "countItemsPresent":
            // for pages that are in multiple variants, we need to handle them by runtime
            switch (runtime) {
              case AppRuntimes.returnista:
                timedOutPage = currentPageName;
                handleInactiveReturnistaUser();
                break;
              case "return-portal":
                timedOutPage = currentPageName;
                handleInactiveReturnPortalUser();
                break;
              default:
                // on these pages, forcefully time the user out
                // NOTE: Fatal errors cannot be recovered without reloading the page
                timedOutPage = currentPageName;
                dispatch(onFatalError(FatalErrors.timeout));
            }
            break;
          case "confirmation":
          case "scanBagCode":
          case "typeBagBarcode":
            // Do nothing here
            break;
        }

        // Only create GA event if the user is navigated for inactivity
        if (timedOutPage != "") {
          ga.event({
            category: AnalyticCategories.UserInteraction,
            action: `User timed out due to inactivity`,
            label: currentPageName
          });
        }
      }

      if (claims !== undefined && now > claims.exp) {
        const sendTimeoutEvent = () => {
          ga.event({
            category: AnalyticCategories.UserInteraction,
            action: "User's Login expired",
            label: currentPageName
          });
        }

        // Clear the session and force the user to login in again. The sign out page handles this already
        switch (runtime) {
          case AppRuntimes.returnista:
            sendTimeoutEvent();
            dispatch(goToPage("signOut"));
            break;
          case "return-portal":
            sendTimeoutEvent();
            handleInactiveReturnPortalUser();
            break;
          default:
            dispatch(setToken(""));
            dispatch(onFatalError(FatalErrors.sessionExpired));
        }
        // TODO: This does not persist through the clearing of the session, needs further investigation
        // setLocalErrorMessage("Your login session has timed out. Please sign in again");
      }
    }, 1000)

    document.addEventListener('mousemove', resetInactivityThreshold);
    document.addEventListener('keydown', resetInactivityThreshold);

    // when the currentPageName changes, we unbind all of our listeners
    // to prevent a stale timer from triggering
    return () => {
      clearInterval(intervalID)
      document.removeEventListener('mousemove', resetInactivityThreshold);
      document.removeEventListener('keydown', resetInactivityThreshold);
    }
  }, [currentPageName, token]);

  const preventUnload = useCallback(event => {
    const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
    ga.event({
      category: category,
      action: CommonPageActions.BrowserBackButtonRefresh
    })
  }, [currentModalPageName, currentPageName])
  //Send GA4 event when back button clicked
  useEffect(() => {
    window.addEventListener('beforeunload', preventUnload);
    return () => {
      window.removeEventListener("beforeunload", preventUnload);
    };
  }, [preventUnload]);

  //----------------------------------------------------------------------------
  // CALLBACKS
  //
  const onClickHeaderLink = (linkLabel: "leftHeaderLink" | "rightHeaderLink") => {
    if (!pages || !currentPageName || !pages[currentPageName]) { return }
    const headerLink = pages[currentPageName][linkLabel];

    if (!headerLink) { return }

    // if this link is supposed to close the page, use the fatal error mechanism
    // to notify the user and send them to the end of the experience
    if (headerLink.destinationModal === Alerts.returnIncomplete) {
      if (currentPageName === "instanceList") {
        dispatch(goToPage("countItemsPresent"))
      } else {
        dispatch(showAlert(headerLink.destinationModal))
      }
      return
    }

    // this is specifically for testing returnista in a web browser
    if (linkLabel === "leftHeaderLink" && currentPageName === "typeExpressCode") {
      if (!window?.webkit?.messageHandlers?.toggleMessageHandler) {
        dispatch(goToPage("home"))
        return
      }
    }

    // reset customer if we're navigating back from the count items page
    if (currentPageName === "countItemsPresent" && runtime === AppRuntimes.returnista) {
        dispatch(customerReset())
        dispatch(goToPage(headerLink.destinationPage))
        return
    }

    // only reset the items present when we're navigating back from the instance list page
    if (currentPageName === "instanceList" && runtime === AppRuntimes.returnista) {
      dispatch(resetItemsMarkedForCompletion())
      dispatch(goToPage("countItemsPresent"))
      return
    }

    if (currentPageName === "instanceList") {
      dispatch(resetItemsMarkedForCompletion())
      dispatch(goToPage("countItemsPresent"))
      return
    }

    // if we are going BACK from the typeBagBarcode page, make sure we open the scanner in bagBarcode mode
    if (currentPageName === "typeBagBarcode" && runtime === AppRuntimes.returnista) {
      dispatch(setLocalErrorMessage(""))

      // if we came from an alternate confirmation page (e.g. confirmationScanTote), we should go back there
      dispatch(goToPage(confirmationPath || "confirmation"))

      openSwiftCameraScanner("bagBarcode")
      return
    }

    // if we are going back from the typeToteBarcode page, make sure we open the scanner in toteBarcode mode
    if (currentPageName === "typeToteBarcode" && runtime === AppRuntimes.returnista) {
      dispatch(setLocalErrorMessage(""))
      dispatch(goToPage("confirmationScanTote"))
      openSwiftCameraScanner("toteBarcode")
      return
    }

    // if this is a regular link, go to the destination
    if (headerLink.destinationPage) {
      // // if the page we're going back to is scanExpressCode
      // // we should reset the return info
      if (headerLink.destinationPage === "scanExpressCode" && runtime == AppRuntimes.returnista) {
        dispatch(customerReset());

      // we use this in case we have to block the user from navigating to another page
      } else if (pendingChangesModalCallbacks[runtime] && pendingChangesModalCallbacks[runtime][currentPageName]) {
        const { shouldRender } = pendingChangesModalCallbacks[runtime][currentPageName];
        if (shouldRender()) {
          setShowPendingChangesModal(true);
          return;
        }
      }
      dispatch(goToPage(headerLink.destinationPage))
    }
  }

  const onKeyPressEnterHeaderLink = (e, label: "leftHeaderLink" | "rightHeaderLink") => {
    handleEnterKeyPress(e, () => onClickHeaderLink(label));
  }

  const hideModal = () => {
    dispatch(clearAlert())
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // POLYFILLS
  //
  objectFitImages();
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // RENDER METHODS
  //
  const renderHeader = () => {
    let leftHeaderLink;
    let rightHeaderLink;

    // do not render the header if a page has the hideHeader property set to true
    // of if we're not on a page
    if (pages[currentPageName]?.hideHeader === true || currentPageName === "") {
      return;
    }

    if (pages && currentPageName && pages[currentPageName]) {
      leftHeaderLink = pages[currentPageName].leftHeaderLink;
      rightHeaderLink = pages[currentPageName].rightHeaderLink;
    }

    const renderLeftHeader = () => {
      // the home page on Android is the only instance where we want the header
      // title to be left-aligned, so return early and render nothing for left header
      if (platform && currentPageName === "home") {
        return
      }

      return (
        <$HeaderLeft>
        {!fatalErrorName && leftHeaderLink?.arrow &&
        <$HeaderLeftArrow
          onClick={() => onClickHeaderLink("leftHeaderLink")}
        >
          <SVG name="backArrow" />
        </$HeaderLeftArrow>
        }
        {/* when screen is wide, show full link label (if it's set) */}
        {leftHeaderLink?.linkLabelFull &&
        <$HeaderLeftText
          tabIndex={0}
          className="hide-on-small-browser-width"
          onClick={() => onClickHeaderLink("leftHeaderLink")}
          onKeyDown={(e) => onKeyPressEnterHeaderLink(e, "leftHeaderLink")}
          data-cy={DataCyStrings.leftNavigationHeaderButton}
        >
          {!fatalErrorName && leftHeaderLink.linkLabelFull}
        </$HeaderLeftText>
        }
        {/* when screen is narrow, show abbreviated link label */}
        {leftHeaderLink?.linkLabelShort &&
        <$HeaderLeftText
          tabIndex={0}
          className="hide-on-large-browser-width"
          onClick={() => onClickHeaderLink("leftHeaderLink")}
          onKeyDown={(e) => onKeyPressEnterHeaderLink(e, "leftHeaderLink")}
          data-cy={DataCyStrings.leftNavigationHeaderShortButton}
        >
          {!fatalErrorName && leftHeaderLink.linkLabelShort}
        </$HeaderLeftText>
        }
      </$HeaderLeft>
      )
    }

    const renderRightHeader = () => {
      // XXX Workaround to conditionally hide 'Skip' on the TypeExpressCode page if
      // there is not a retailer configured for the location.
      // TODO: Make a better system for conditionally rendering this
      if(currentPageName == "typeExpressCode" && !allowSkip) {
        return (
          <$HeaderRight/>
        );
      }

      return (
        <$HeaderRight>
        {/* when screen is wide, show full link label (if it's set) */}
        {rightHeaderLink?.linkLabelFull &&
        <$HeaderRightText
          tabIndex={0}
          className={"hide-on-small-browser-width"}
          onClick={() => onClickHeaderLink("rightHeaderLink")}
          onKeyDown={(e) => onKeyPressEnterHeaderLink(e, "rightHeaderLink")}
          data-cy={DataCyStrings.rightNavigationHeaderButton}
        >
          {!fatalErrorName && rightHeaderLink.linkLabelFull}
        </$HeaderRightText>
        }
        {/* when screen is narrow, show abbreviated link label */}
        {rightHeaderLink?.linkLabelShort &&
        <$HeaderRightText
          tabIndex={0}
          className="hide-on-large-browser-width"
          onClick={() => onClickHeaderLink("rightHeaderLink")}
          onKeyDown={(e) => onKeyPressEnterHeaderLink(e, "rightHeaderLink")}
          data-cy={DataCyStrings.rightNavigationHeaderButton}
        >
          {!fatalErrorName && rightHeaderLink.linkLabelShort}
        </$HeaderRightText>
        }
        {!fatalErrorName && rightHeaderLink?.arrow &&
        <$HeaderRightArrow onClick={() => onClickHeaderLink("rightHeaderLink")} data-cy={DataCyStrings.rightNavigationHeaderButton}>
          <SVG name="backArrow"/>
        </$HeaderRightArrow>
        }
      </$HeaderRight>
      )
    }
    const imageHandlers = handleEnterKeyPressOnClickHandlers(() => links?.homeURL && window.open(links?.homeURL, "_blank"));
    const onRetailerLogoClick = () => {
      const category = getCategoryFromPageOrModalName(currentPageName, currentModalPageName)
      ga.event({
        category: category,
        action: CommonPageActions.MerchantLogo
      })
    }

    return (
      <$Header>
        {/* when fatalErrorName is set, hide links and other controls on our header
            (currently we show the header as usual on our fatal error page, but we don't
            want to allow interaction because the site flow is supposed to end there */}
        {disableHeaderLinks ? <div></div> : renderLeftHeader()}
        {isAdminMode && <$AdminModeBadge>{t('adminMode')}</$AdminModeBadge>}
        {runtime === AppRuntimes.returnista && pages[currentPageName]?.title != undefined ? (
          <$HeaderTitle data-cy={DataCyStrings.appHeaderCenter}>
            {pages[currentPageName].title}
          </$HeaderTitle>
        ) : (
          <$HeaderLogo data-cy={DataCyStrings.appHeaderCenter}>
            {links?.logo ?
              <RetailerLogo onClick={onRetailerLogoClick} homeURL={links?.homeURL} logoURL={links?.logo} alt={retailerLogoAltText}/> :
              <SVG name="hr-logo-paypal" alt={t('retailerNameLogo', {retailerName: 'Happy Returns'})} data-cy={DataCyStrings.logo}/>
            }
          </$HeaderLogo>
        )
        }
        {disableHeaderLinks ? <div></div> : renderRightHeader()}
      </$Header>
    )
  }

  // this is our version of a router, based on currentPageName do a conditional render
  const renderCurrentPage = () => {
    const page: Page = pages[currentPageName];

    switch (currentPageName) {
      case "startPartnerReturn"                  : return (<StartPartner                 page={page} />)
      case "startReturnPortal"                   : return (<StartReturnPortal            page={page} />)
      // same page as preview but has different properties in page config
      case "walkupPreview"                       : return (<Preview                      page={page} />)
      case "preview"                             : return (<Preview                      page={page} />)
      case "typeExpressCode"                     : return (<TypeExpressCode              page={page} />)
      case "success"                             : return (<Success                      page={page} />)
      case "signOut"                             : return (<SignOut                      page={page} />)
      case "returnComplete"                      : return (<ReturnComplete               page={page} />)
      case "returnType"                          : return (<ReturnType                   page={page} />)
      case "orderSearch"                         : return (<OrderSearch                  page={page} />)
      case "orderList"                           : return (<OrderList                    page={page} />)
      case "returnReasons"                       : return (<ReturnReasons                page={page} />)
      case "returnReasonsChildren"               : return (<ReturnReasonsChildren        page={page} />)
      case "reasonNotes"                         : return (<ReasonNotes                  page={page} />)
      case "returnOptions"                       : return (<ReturnOptions                page={page} />)
      case "noReturnOptions"                     : return (<NoReturnOptions              page={page} />)
      case "exchange"                            : return (<Exchange                     page={page} />)
      case "confirmationWithoutReverseLogistics" : return (<ConfirmationWithoutLogistics page={page} />)
      case "loginReturnPortal"                   : return (<LoginReturnPortal            page={page as RPLC} />)
      case "missingReturnEmail"                  : return (<MissingReturnEmail           page={page} />)
      case "dropoffMethods"                      : return (<DropoffMethods               page={page} />)
      case "returnStarted"                       : return (<ReturnStarted                page={page} />)
      case "appUninstalled"                      : return (<Uninstalled                  page={page} />)
      case "typeStoreNumber"                     : return (<TypeStoreNumber              page={page} />)
      case "refundOptions"                       : return (<RefundOptions                page={page} />)
      case "shippingLabelRequestForm"            : return (<ShippingLabelRequestForm     page={page}/>)
      case "shippingLabelSuccessPage"            : return (<ShippingLabelSuccessPage     page={page}/>)
      case "expiredReturn"                       : return (<ExpiredReturn                page={page} />)
      case "returnStatus"                        : return (<ReturnStatus                 page={page} />)
      default                                    : dispatch(onFatalError(FatalErrors.pageNotFound))
    }
  }

  // modal pages are rendered separately than pages (even if the component is technically the same).
  // in order to achieve a layering affect. EX: in return portal, If I'm on the item list page and
  // click on a purchase, a modal will render over the item list page with the item list page
  // always rendered in the background in case the user either finishes navigation or changes
  // their mind and closes the modal
  const renderCurrentModalPage = () => {
    const page: Page = pages[currentModalPageName];
    switch(currentModalPageName) {
      case "returnReasons"         : return (<ReturnReasons page={page} />);
      case "returnReasonsChildren" : return (<ReturnReasonsChildren page={page} />);
      case "reasonNotes"           : return (<ReasonNotes   page={page} />);
      case "returnOptions"         : return (<ReturnOptions page={page} />);
      case "exchange"              : return (<Exchange      page={page} />);
      default                      :
        logger.Error(`Page ${currentModalPageName} was not registered in renderCurrentModalPage`)
        dispatch(onFatalError(FatalErrors.pageNotFound));
    }
  }

  const renderFatalError = () => {
    // default to fatalErrorUnknown values, they are safe if we can't detect the error
    let headline = t('somethingWentWrong');
    let message = t('anUnknownErrorHas');
    let showSupportCopy = false;
    let supportCopyLowercaseFirstLetter = false;
    // all these errors are handled gracefully so we only need to know the error name to keep track of user navigation
    fatalErrorName && logger.Error("fatal error", { fatalErrorName });

    switch (fatalErrorName) {
      case "fatalErrorRmaExpired":
        headline = t('invalidExpressCode');
        message = t('retailerDeactivated');
        break;
      case "fatalErrorLocation":
        headline = t('locationCannotBeFound');
        message = t('weDoNotHaveARecord');
        showSupportCopy = true;
        break;
      case "fatalErrorToken":
        headline = t('accessDenied');
        message = t('weWerentAbleToVerify');
        showSupportCopy = true;
        break;
      case "fatalErrorRma":
        headline = t('invalidExpressCode');
        message = t('pleaseTryReEntering');
        break;
      case "fatalErrorRmaUnassociated":
        headline = t('unassociatedBarcode');
        message = t('theCodeProvidedIs');
        break;
      case "fatalErrorUnknown":
        headline = t('somethingWentWrong');
        message = t('anUnknownErrorHas');
        break;
      case "fatalErrorConnection":
        headline = t("cannotEstablishAConnection", 'Cannot Establish a Connection');
        message = t("pleaseCheckYourInternet", "Please check your internet connection and try again.");
        supportCopyLowercaseFirstLetter = true;
        showSupportCopy = true;
        break;
      case "fatalErrorReturnAlreadyComplete":
        headline = t('thisReturnIsAlready');
        message = t('allItemsAssociated');
        break;
      case "fatalErrorTimeout":
        headline = t('returnCancelledDue');
        message = t('pleaseCloseThisWindow');
        break;
      case "fatalErrorSessionExpired":
        headline = t('sessionExpired')
        message = t('pleaseCloseThisWindow');
        break;
      case "fatalErrorPageNotFound":
        headline = t('pageNotFound');
        message = t('thePageYouRequested');
        break;
      case "fatalErrorRmaPartialRefund":
        headline = t('noLongerWorkingTitle');
        message = t('startNewReturnMessage');
        break;
      case "fatalErrorRmaExpiredReturn":
        headline = t('noLongerWorkingTitle');
        message = t('startNewReturnMessage');
        break;
      case "fatalErrorInactiveLocation":
        headline = t('inactiveLocationTitle');
        message = t('inactiveLocationMessage');
        break;
      case "fatalErrorReturnBarIneligible":
        headline = t('returnBarIneligible');
        message = t('contactRetailerMessage');
        break;
      default:
        if (fatalErrorName != "fatalErrorUnknown") logger.Error("Trying to show unknown modal named " + fatalErrorName);
    }

    // SPECIAL CASE -- one of these needs a warning -- not an error
    if (fatalErrorName == "fatalErrorReturnAlreadyComplete") {
      return (
        <FinalWarning
          primaryMessage={headline}
          subMessages={[message]}
        />
      )
    }

    return (
      <FinalError
        primaryMessage={headline}
        subMessages={[message]}
        supportCopyLowercaseFirstLetter={supportCopyLowercaseFirstLetter}
        showSupportCopy={showSupportCopy}
      />
    )
  }

  const renderAlertModal = () => {
    let title = "";
    let body = "";
    let primaryButtonLabel = "";
    let hideAlertModal = hideModal;
    let primaryButtonOnClick: Function | undefined = undefined;

    switch (alertName) {
      case "returnIncomplete":
        title = t('incompleteReturn');
        if (runtime === AppRuntimes.staples) {
          primaryButtonOnClick = () => {
            window.close();
          }
          body = t('areYouSureYouWant')
          primaryButtonLabel = t('endReturn');
        } else {
          body = t('pleaseReviewTheReturn');
          primaryButtonLabel = t('continueProcessing');
        }
        break;
      case "userInactive":
        title = t('returnPausedDueTo');
        body = t('pleaseContinueAdding');
        primaryButtonLabel = t('continueProcessing');
        dispatch(refreshToken((e) => {
          console.error(e);
        }));
        break;
      case "bagAlreadyScanned":
        title = t('bagAlreadyScanned');
        body = t('thisBagIsAlreadyAssociated');
        primaryButtonLabel = t('continueProcessing');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "confirmation") {
            openSwiftCameraScanner("bagBarcode")
          }
        }
        break;
      case "bagAlreadyUsed":
        title = t('bagAlreadyScanned');
        body = t('thisBagIsAlreadyUsed');
        primaryButtonLabel = t('continueProcessing');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "confirmation") {
            openSwiftCameraScanner("bagBarcode")
          }
        }
        break;
      case "returnAlreadyComplete":
        title = t('theReturnIsAlready');
        body = t('allItemsAssociated');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert());
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip)
          }
        }
        break;
      case "invalidRMA":
        title = t('thisCodeIsntAssociated');
        body = t('pleaseTryAnotherCode');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip)
          }
        }
        break;
      case "partialRefundRMA":
        title = t('noLongerWorkingTitle');
        body = t('startNewReturnMessage');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip)
          }
        }
        break;
      case "expiredReturnRMA":
        title = t('noLongerWorkingTitle');
        body = t('startNewReturnMessage');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip)
          }
        }
        break;
      case "locationNotAllowed":
        title=t('qrCodeAssignedToAnother');
        body=t('pleaseLetTheCustomer');
        primaryButtonLabel=t('close');
        hideAlertModal = () => {
          dispatch(clearAlert());
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip);
          }
        }
        break;
      case "returnBarIneligible":
        title = t('returnBarIneligible');
        body =t('contactRetailerMessage');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "scanExpressCode") {
            openSwiftCameraScanner("confirmationCode", undefined, allowSkip)
          }
        }
        break;
      case "invalidBagBarcode":
        title = t('thisCodeIsntAssociated');
        body = t('pleaseTryAnotherCode');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "confirmation") {
            openSwiftCameraScanner("bagBarcode")
          }
        }
        break;
      case "invalidToteBarcode":
        title = t('thisCodeIsntAssociated');
        // body = t('pleaseTryAnotherToteCode'); TODO fix for iPad
        body = "Please try another code. If the problem persists, use and scan a different tote or manually enter the store number to continue."
        primaryButtonLabel = t('close');

        hideAlertModal = () => {
          dispatch(clearAlert());

          if (runtime === AppRuntimes.returnista && currentPageName === "confirmationScanTote") {
            openSwiftCameraScanner("toteBarcode");
          }
        }

        break;
      case "barcodeAlreadyUsed":
        title = t('thisBagHasAlready');
        body = t('pleaseTryAnotherBag');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          if (runtime === AppRuntimes.returnista && currentPageName === "confirmation") {
            openSwiftCameraScanner("bagBarcode")
          }
        }
        break;
      case "fatalError":
        title = t('somethingWentWrong');
        body = t('anUnknownErrorHas');
        primaryButtonLabel = t('close');
        hideAlertModal = () => {
          dispatch(clearAlert())
          dispatch(appReset())
          dispatch(customerReset())
          dispatch(goToPage("home"))
        }
        break;
      default:
        logger.Warning("Trying to show unknown modal named " + alertName);
    }

    return (
      <AlertModal
        onHide={hideAlertModal}
        primaryButtonOnClick={primaryButtonOnClick}
        title={title}
        message={body}
        buttonLabel={primaryButtonLabel}
        runtime={runtime}
        platform={platform}
      />
    )
  }
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // DETERMINATION OF WHAT TO RENDER
  // if a fatal error has happened, show only the header and the error itself
  if (fatalErrorName) {
    return (
      <div>
        <RetailerStyles colors={colors} links={links} runtime={runtime}/>
        {renderHeader()}
        {renderFatalError()}
      </div>
    )
  }
  const getBackgroundColorFromRuntime = () => {
    switch (runtime) {
      default:
        return "white";
    }
  }
  // if there's no error, render our header and our usual pagestuffs
  return (
    <div className={`app ${platform}`} style={{ display: "flex", flexDirection: "column", backgroundColor: getBackgroundColorFromRuntime() }}>
      { /*  the app fully controls the header, the pages don't even
            really know it exists */
        renderHeader()
      }
      <RetailerStyles colors={colors} links={links} runtime={runtime} />
      {/* if we're on a page, render it! */
        !returnPortalTimeout && currentPageName &&
        <$MainContent className={!links?.backgroundImgMobile ? "no-mobile-bg" : ""}>
          {isInitialized && renderCurrentPage()}
        </$MainContent>
      }
      {returnPortalTimeout && <TimeoutModal/>}

      {currentModalPageName && renderCurrentModalPage()}
      { /* if a modal is active, show it on top of our content */
        alertName && renderAlertModal()
      }

      {showPendingChangesModal &&
        pendingChangesModalCallbacks[runtime] &&
        pendingChangesModalCallbacks[runtime][currentPageName] &&
        <PendingChangesModal
          discardChanges={pendingChangesModalCallbacks[runtime][currentPageName].discardChanges}
          keepChanges={pendingChangesModalCallbacks[runtime][currentPageName].keepChanges}
          runtime={runtime}
        />
      }

      { /* loading indicator on top of modal */
        loadingMessage && <LoadingIndicator message={loadingMessage} />
      }
    </div>
  );
  //----------------------------------------------------------------------------
};

interface RetailerStylesProps {
  colors?: {
    [key: string]: string
  },
  links?: {
    [key: string]: string
  },
  runtime: AppRuntimes
}

const RetailerStyles = createGlobalStyle<RetailerStylesProps>`
:root {
  --color-primary:           ${({ colors }) => colors?.primaryColor ? colors.primaryColor : "black"};
  --color-header:            ${({ runtime }) => runtime === "return-portal" ? "white" : "#FFDE00"};
  --image-background:        url(${({ links }) => links?.backgroundImg ? links.backgroundImg : ""});
  --image-background-mobile: url(${({ links }) => links?.backgroundImgMobile ? links.backgroundImgMobile : ""});
}
`;

export default App;
