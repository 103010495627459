import React from "react";

import { SVG } from "../../../../components/svg";
import { DropoffMethodIDs } from "../../../../redux/customer/types";
import { DataCyStrings } from "../../../../types/DataCyStrings";
import { ReturnStatus } from "../../../../types/ReturnStatus";
import { getTextColorFromBackgroundBrightness } from "../../../../utility";
import getTranslator from "../../../../utility/getTranslator";
import useSelector from "../../../../utility/useTypedSelector";

import { ReturnStatusComposite } from "../../index";

import {
  $ActiveProgressBar,
  $ProgressBarContainer,
  $StatusBarBackground,
  $StatusBarContainer,
  $StatusNode,
  $StatusNodeContainer,
  $StatusNodeTextContainer,
} from "./styles";

// TODO Add GA events when GA re-enabled
export const StatusBar = ({ deliveryDate, departureDate, mostRecentReceivedAtDate, dropoffMethodID, returnStatus }: ReturnStatusComposite) => {
  const isApprovedState = () => {
    return (
      returnStatus === ReturnStatus.complete ||
      returnStatus === ReturnStatus.partial ||
      returnStatus === ReturnStatus.keptInStore
    );
  };

  if (
    returnStatus === ReturnStatus.expired ||
    isApprovedState() ||
    (
      dropoffMethodID !== DropoffMethodIDs.mail &&
      dropoffMethodID !== DropoffMethodIDs.mailNoBoxNoLabel &&
      dropoffMethodID !== DropoffMethodIDs.mailNoLabel &&
      !(dropoffMethodID === DropoffMethodIDs.returnBar && returnStatus === ReturnStatus.droppedOff && mostRecentReceivedAtDate)
    )
  ) {
    return <></>;
  }

  const { app } = useSelector((store) => store);
  const { colors } = app;
  const { t } = getTranslator("ReturnStatusCard")();
  const checkColor = colors?.primaryColor ? getTextColorFromBackgroundBrightness(colors.primaryColor) : "white";

  const renderMailStatusBar = () => {
    const NODE_COUNT = 4;

    // 1 edge = 2 segments, we need to represent midway points between nodes
    const SEGMENT_COUNT = (NODE_COUNT - 1) * 2;

    const getActiveSegmentsCount = () => {
      if (deliveryDate) return 5;
      if (departureDate) return 3;
      return 1;
    };

    const renderProgressBar = () => {
      const activeSegmentsCount = getActiveSegmentsCount();
      const segments = new Array(SEGMENT_COUNT);

      // Fill the segments array with elements and provide unique keys to active segments
      for (let i = 0; i < activeSegmentsCount; i++) {
        if (i === 0) {
          segments[i] = <$ActiveProgressBar key={`active-segment-${i}`} borderRadius={"24px 0 0 24px"} />;
        } else {
          segments[i] = <$ActiveProgressBar key={`active-segment-${i}`} />;
        }
      }

      // provide unique keys to inactive segments
      for (let i = activeSegmentsCount; i < SEGMENT_COUNT; i++) {
        segments[i] = <div key={`inactive-segment-${i}`} />;
      }

      return <>{segments.map((segment) => segment)}</>;
    };

    return (
      <$StatusBarContainer data-cy={DataCyStrings.returnStatusStatusBar}>
        <$StatusBarBackground />
        <$ProgressBarContainer>{renderProgressBar()}</$ProgressBarContainer>
        <$StatusNodeContainer>
          <$StatusNodeTextContainer>
            <$StatusNode checkColor={checkColor}>
              <SVG name={"circleCheckFilled"} />
            </$StatusNode>
            <span>{t("statusBarStarted")}</span>
          </$StatusNodeTextContainer>
          <$StatusNodeTextContainer alignItems={"center"}>
            <$StatusNode checkColor={checkColor}>{departureDate && <SVG name={"circleCheckFilled"} />}</$StatusNode>
            <span>{t("statusBarDeparted")}</span>
          </$StatusNodeTextContainer>
          <$StatusNodeTextContainer alignItems={"center"}>
            <$StatusNode checkColor={checkColor}>{deliveryDate && <SVG name={"circleCheckFilled"} />}</$StatusNode>
            <span>{t("statusBarDelivered")}</span>
          </$StatusNodeTextContainer>
          <$StatusNodeTextContainer alignItems={"flex-end"}>
            <$StatusNode />
            <span>{t("statusBarApproved")}</span>
          </$StatusNodeTextContainer>
        </$StatusNodeContainer>
      </$StatusBarContainer>
    );
  }

  const renderReturnBarStatusBar = () => {
    const NODE_COUNT = 3;

    // 1 edge = 2 segments, we need to represent midway points between nodes
    const SEGMENT_COUNT = (NODE_COUNT - 1) * 2;

    const getActiveSegmentsCount = () => {
      if (mostRecentReceivedAtDate) return 3;
      return 1;
    };

    const renderProgressBar = () => {
      const activeSegmentsCount = getActiveSegmentsCount();
      const segments = new Array(SEGMENT_COUNT);

      // Fill the segments array with elements and provide unique keys to active segments
      for (let i = 0; i < activeSegmentsCount; i++) {
        if (i === 0) {
          segments[i] = <$ActiveProgressBar key={`active-segment-${i}`} borderRadius={"24px 0 0 24px"} />;
        } else {
          segments[i] = <$ActiveProgressBar key={`active-segment-${i}`} />;
        }
      }

      // provide unique keys to inactive segments
      for (let i = activeSegmentsCount; i < SEGMENT_COUNT; i++) {
        segments[i] = <div key={`inactive-segment-${i}`} />;
      }

      return <>{segments.map((segment) => segment)}</>;
    };

    return (
      <$StatusBarContainer data-cy={DataCyStrings.returnStatusStatusBar}>
        <$StatusBarBackground />
        <$ProgressBarContainer>{renderProgressBar()}</$ProgressBarContainer>
        <$StatusNodeContainer>
          <$StatusNodeTextContainer>
            <$StatusNode checkColor={checkColor}>
              <SVG name={"circleCheckFilled"} />
            </$StatusNode>
            <span>{t("statusBarStarted")}</span>
          </$StatusNodeTextContainer>
          <$StatusNodeTextContainer alignItems={"center"}>
            <$StatusNode checkColor={checkColor}>{mostRecentReceivedAtDate && <SVG name={"circleCheckFilled"} />}</$StatusNode>
            <span>{t("statusBarDroppedOff")}</span>
          </$StatusNodeTextContainer>
          <$StatusNodeTextContainer alignItems={"flex-end"}>
            <$StatusNode />
            <span>{t("statusBarApproved")}</span>
          </$StatusNodeTextContainer>
        </$StatusNodeContainer>
      </$StatusBarContainer>
    );
  }

  if (dropoffMethodID === DropoffMethodIDs.returnBar && returnStatus === ReturnStatus.droppedOff && mostRecentReceivedAtDate) {
    return renderReturnBarStatusBar()
  } else {
    return renderMailStatusBar()
  }
};
