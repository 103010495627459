import { $LoginBase } from "../LogIn/styles";
import styled from "styled-components";
import { modalZIndex } from "../../utility";
import { $MainContent } from "../../app/styles";
import { MOBILE_BREAKPOINT } from "../../globalConstants";

const $LoginReturnPortal = styled.div`
  align-items: stretch;
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow-wrap: break-word;
  width: 100%;
  .g-recaptcha > div > div {
    z-index: 1000000;
    @media (max-width: ${MOBILE_BREAKPOINT}px){
      transform:scale(0.71);
      -webkit-transform:scale(0.71);
      transform-origin:0 0;
      -webkit-transform-origin:0 0;
      display: block;
      transition: right 0.3s ease 0s;
      position: fixed;
      bottom: 20px !important;
      right: 0;
    }
  }
  .g-recaptcha {
    position: absolute;
  }
  .grecaptcha-badge {
    position: absolute;
    margin-bottom: 21px;
  }
  ${$LoginBase}
  position: relative;
  .mobile-background {
    width: 100vw;
    height: 250px;
    background-image: var(--image-background-mobile);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: ${modalZIndex - 2};
    position: absolute;
    top: 0;
    @media (min-width: 759px) {
      & {
        display: none;
      }
    }
  }
  .login-container {
    z-index: ${modalZIndex - 1};
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: initial;
    align-self: center;
    margin-bottom: 16px;
    margin-top: 36px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
      width: 343px;
      align-items: initial;
      margin-top: 16px;
    }

    .header {
      font-size: 15px;
      line-height: 20px;
      width: 100%;
      margin: 12px 0;
      padding: 0 16px;
      text-align: center;
      color: var(--header-grey);
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        margin: 12px 0 10px 0;
      }
    }
    .login-splitter {
      border-top: 1px solid var(--border-grey);
      width: 100%;
      margin-bottom: 16px;
    }
    .privacy-policy {
      color: var(--soft-black);
      width: 100%;
      padding-top: 16px;
      padding-bottom: 8px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        font-size: 12px;
      }

      a {
        color: var(--soft-black);
      }
    }
    .one-login-offering,
    .two-login-offerings {
      font-size: 12px;
      line-height: 18px;
      width: 100%;
      .change-login-offering {
        text-decoration: underline;
        cursor: pointer;
        display: inline-block;
        margin-left: 2px;
        margin-bottom: 16px;
      }
    }
    .two-login-offerings {
      display: flex;
      justify-content: space-between;
      > div:last-child {
        text-align: right;
      }
    }
    .one-login-offering {
      text-align: center;
    }
    form {
      margin-top: 12px;
      width: 100%;
      @media (max-width: ${MOBILE_BREAKPOINT}px) {
        display: block;
        margin-top: 10px;
      }

      * {
        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          display: inherit;
        }
      }
      input {
        font-size: 16px;
        font-family: "sofia-pro", sans-serif;
        margin-top: 4px;
        margin-bottom: 16px;
        height: 48px;
        border: 1px solid var(--border-card);
        &:focus {
          border: 2px solid var(--text-black);
          box-shadow: none;
        }

        @media (max-width: ${MOBILE_BREAKPOINT}px) {
          margin-bottom: 16px;
        }
      }
    }
    .order-number-message {
      font-size: 14px;
      margin-bottom: 16px;
      margin-top: -12px;
      line-height: 18px;
      color: var(--header-grey);
    }
    button {
      height: 48px;
      width: 100%;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
    }
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100vw;
  }

`;

export default $LoginReturnPortal;
