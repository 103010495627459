import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { isEmpty} from 'lodash';
import logger from "../../utility/logger/logger";

import useSelector from "../../utility/useTypedSelector";
import { PageProps } from "../types";
import { Return, Instance } from "../../types/Instance";
import {
  showLoadingIndicator,
  clearLoadingIndicator,
  onFatalError,
  goToPage,
  showAlert
} from "../../redux/app/actions";
import { setNewReturnEmail, setRetailer } from "../../redux/customer/actions";
import { actions as globalGoogleAnalyticsActions } from "../../redux/slices/globalGoogleAnalytics";
import { Store as CustomerStore, Returns, ReturnShoppingItem } from "../../redux/customer/types";
import {
  containsDigit,
  checkIfItemHasValidPrice,
  getAdminMode,
  getCustomerEmailFromReturn,
  getReturnistaJWTClaims,
  getSavedRBLocations,
  handleAxiosError,
  prepareReturn
} from "../../utility";
import { PageLifecycle } from "../"
import {
  $PreviewContentBox,
  $ReturnPreviewCard,
  $ReturnShoppingPreviewCard,
  $ReturnTotal
} from "./styles";
import ReturnCountFooter from "../../components/ReturnCountFooter";
import PrimaryButton from "../../components/Button/PrimaryButton";
import EmailModal from "../../components/EmailModal";
import { Alerts, FatalErrors } from "../../types/LifeCycle";
import { ReturnPortalBaseProps, ReturnPortalBaseWithPage } from "../../components/ReturnPortalBase";
import { ErrorAlertMessage, InfoMessage } from "../../components/AlertMessage";
import ItemDetails from "../../components/ItemDetails";
import { AppRuntimes } from "../../types/AppRuntimes";
import getTranslator from "../../utility/getTranslator";
import { DataCyStrings } from "../../types/DataCyStrings";
import ga from "../../utility/GAEmitter";
import { WrapIf } from "../../components/Wrap";
import { SVG } from "../../components/svg";
import {
  AnalyticCategories,
  AnalyticsPage,
  AnalyticsPageRoutes,
  CommonPageActions,
  PreviewPageActions
} from "../../types/Analytics";
import { $ScreenReaderOnlyComponent } from "../ScreenReaderOnlyComponent/styles";

const useTranslation = getTranslator("Preview")

export const ProcessingFeeStatus = {
  NotWaived: "not_waived",
  WaivedDisplay: "waived_display",
  WaivedHide: "waived_hide",
  WaivedReadonlyDisplay: "waived_readonly_display",
  WaivedReadonlyHide: "waived_readonly_hide",
};

const processingFeeKey = "Processing fee";
const taxKey = "Tax";

interface ReturnItems {
  refund: Array<Instance>,
  exchange: Array<Instance>,
  storeCredit: Array<Instance>,
}

//==============================================================================
class PreviewLifecycle extends PageLifecycle {
  customer: CustomerStore;

  constructor(page, dispatch, app, customer) {
    super(page, dispatch, app);
    this.customer = customer;
  }
  satisfiesPreconditions() {
    return true
  }
}

const gaCountItemsPresentEventContent = {
  noErrors: "User submitted item count without errors",
}

export const EmailNeededModal = ({isOpen, onClose, onSuccess}) => {
  const {t} = useTranslation()
  return (
    <EmailModal
      isOpen={isOpen}
      onClose={onClose}
      onSuccess={onSuccess}
      primaryMessage={t('emailAddressForReturn')}
      subMessage={
        t('weCouldntFindYour')
      }
    />
  )
}
//==============================================================================

//==============================================================================
const Preview = ({page}: PageProps) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const { customer, app } = useSelector(store => store);
  const { returns,
          itemsMarkedForCompletion,
          itemsPresent,
          returnType,
          query,
          newReturnEmail,
          selectedDropoffMethod,
          purchasing,
          returnSessionID
        } = customer;
  const { loadingMessage,
          runtime,
          copies,
          previewPageHasBeenVisited,
          instanceListCountItemsPresentErrorCount,
          returnShoppingEnabled,
          returnShoppingDiscount,
          locale } = app;
  const [isAdminMode, setIsAdminMode] = useState<boolean>(getAdminMode());
  const retailerName = copies?.retailerName;
  const lifecycle = new PreviewLifecycle(page, dispatch, app, customer);

  const [newReturn, setNewReturn] = useState({});

  const [refundVisible, setRefundVisible] = useState(false);
  const [inYourCartVisible, setInYourCartVisible] = useState(false);

  const [sortedReturnItems, setSortedReturnItems] = useState<ReturnItems>({
    refund: [],
    storeCredit: [],
    exchange: [],
  })

  const [returnShoppingItems, setReturnShoppingItems] = useState<ReturnShoppingItem[]>([]);

  const [tallies, setTallies] = useState({
    total: "",
    subtotal: "",
    adjustments: {} as Record<string, string>,
    adjustmentsLocalized: {},
    distributions: {},
    distributionsLocalized: {},
    processingFeeStatus: ProcessingFeeStatus.NotWaived,
    isTotalValid: false,
    isSubTotalValid: false,
    showAdjustments: false,
    showDistributions: false,
    isTaxIncluded: false
  })

  const [error, setError] = useState("");

  const areItemsEmpty = () => {
    const { refund, storeCredit, exchange } = sortedReturnItems;
    return refund.length === 0 && storeCredit.length === 0 && exchange.length === 0;
  }

  const generateDataCyString = (str: string) => {
    return `${str.toLowerCase().replace(" ", "-")}-amount` as DataCyStrings;
  }

  // Send Another Email modal
  const [displayEmailModal, setDisplayEmailModal] = useState(false);

  const [footer, setFooter] = useState<ReturnPortalBaseProps["footer"]>()
  //----------------------------------------------------------------------------
  //----------------------------------------------------------------------------
  // HOOKS
  //
  // on launch, check preconditions, if all's OK call the sortedReturnItems endpoint
  // to get our data ready for sortedReturnItems
  useEffect(() => {
    if (lifecycle.satisfiesPreconditions()) {
      if (!previewPageHasBeenVisited && instanceListCountItemsPresentErrorCount === 0) {
        dispatch(globalGoogleAnalyticsActions.markPreviewPageAsVisited())
      }
      fetchPreview()
    }
    else {
      dispatch(showAlert(Alerts.fatalError))
    }
  }, []);

  // avoid rendering the footer until we know which footer to render
  useEffect(() => {
    // wait for the loading indicator to clear
    if (loadingMessage.length === 0) {
      const footerType = shouldRenderReturnShoppingPreview() ? "return-shopping" : "dropoff-method"
      setFooter(footerType)
    } else {
      setFooter(undefined)
    }
  }, [returnShoppingItems, loadingMessage])
  useEffect(() => {
    ga.setDimensions({
      user_properties: {
        admin_mode: isAdminMode,
        retailer_name: retailerName,
        locale: locale,
        change_dropoff: false
      }
    })
    ga.sendPageDetails(AnalyticsPageRoutes.Preview, AnalyticCategories.PreviewPage);
  }, []);
  //----------------------------------------------------------------------------

  //----------------------------------------------------------------------------
  // HELPERS
  //
  // pull the sortedReturnItems object from the server based on the current returns
  // since the sortedReturnItems object is used only by this page, we are not
  // storing it in the redux store
  const fetchPreview = async () => {
    try {
      dispatch(showLoadingIndicator(t('generatingPreview')));
      const newReturns = prepareReturn(
        returns,
        returnType,
        itemsMarkedForCompletion,
        query,
        newReturnEmail,
        selectedDropoffMethod,
        purchasing,
        returnSessionID,
        getSavedRBLocations()
      );
      const response = await axios.post<Returns>("/preview", newReturns);
      setNewReturn(newReturns);
      const { returning, retailer } = response.data;
      let orderHasTaxIncluded: boolean|undefined;

      dispatch(setRetailer(retailer))

      const sortedReturnItems = returning.reduce((collection:ReturnItems, currItem) => {
        const { optionID } = currItem.refund;
        if (optionID === "exchange") {
          collection.exchange.push(currItem);
        } else if (optionID === "refund") {
          collection.refund.push(currItem);
        } else if (optionID === "store-credit") {
          collection.storeCredit.push(currItem);
        } else {
          logger.Warning("optionID not defined or unrecognized", currItem);
        }

        if (orderHasTaxIncluded === undefined)
          orderHasTaxIncluded = (currItem.purchase.details?.taxIncluded?.toLowerCase?.() === 'true');

        return collection;
      }, {
        refund: [],
        storeCredit: [],
        exchange: []
      })

      setSortedReturnItems(sortedReturnItems);
      setReturnShoppingItems(purchasing?.items);

      setTallies({
        total: (response.data.total || ""),
        subtotal: (response.data.subtotal || ""),
        distributions: (response.data.distributions || {}),
        adjustments: (response.data.adjustments || {}),
        adjustmentsLocalized: (response.data.adjustmentsLocalized || {}),
        distributionsLocalized: (response.data.distributionsLocalized || {}),
        processingFeeStatus: response.data.processingFeeStatus || ProcessingFeeStatus.NotWaived,
        showAdjustments: showAdjustmentsSection(response.data.adjustments as Record<string, string>),
        showDistributions: showDistributionsSection(response.data.distributions as Record<string, string>),
        isTotalValid: containsDigit(response.data.total || ""),
        isSubTotalValid: containsDigit(response.data.subtotal || ""),
        isTaxIncluded: (orderHasTaxIncluded || false)
      } as any)
    }
    catch (error) {
      // do not handle as a fatal error for the return portal, instead render error on page
      if(runtime == AppRuntimes.returnPortal) {
        if (error?.response?.status >= 400 && error?.response?.status < 500) {
          setError(t('oneOrMoreOfYourItems'));
        } else if (error?.response?.status >= 500) {
          setError(t('somethingHasGoneWrong'));
        }
      } else {
        // XXX we're using the backend timeout for this endpoint
        // to ensure that /preview has enough time for bigger returns
        if (error.request && !error.response) {
          dispatch(onFatalError(FatalErrors.connection))
        } else {
          handleAxiosError(error, dispatch)
        }
      }

      console.error("unable to fetch preview", error);
    }
    finally {
      dispatch(clearLoadingIndicator());
    }
  }

  const showAdjustmentsSection = (adjustments: Record<string, string>): boolean => {
    return Object.values(adjustments || {}).some(value => containsDigit(value));
  };

  const showDistributionsSection = (distributions: Record<string, string>): boolean => {
    return Object.values(distributions || {}).some(value => containsDigit(value));
  };

  const isSubmitReturnButtonDisabled = () => {
    return error != ""
  }

  const shouldShowItemPrices = () => {
    // if current return is manual/new and in the returnista runtime, show prices
    if (runtime === AppRuntimes.returnista) {
      const claims = getReturnistaJWTClaims(app.token);
      // if user is coming from returnista store, show prices
      // by checking if the location is associated w/ a retailer
      // RB users should not see price
      if (claims?.returnsApp?.retailerID) {
        return true;
      }
    }

    // if runtime is the return portal, show prices
    if (runtime === AppRuntimes.returnPortal) return true;

    return false;
  }

  const onEmailModalSuccess = (emailAddress) => {
    dispatch(setNewReturnEmail(emailAddress));
    lifecycle.advance();
  }

  const onSubmitReturnClicked = () => {
    // if email is already found, advance
    if (newReturnEmail) {
      lifecycle.advance();
    } else {
      const potentialEmail = getCustomerEmailFromReturn(newReturn as Return);
      if (potentialEmail !== null) {
        dispatch(setNewReturnEmail(potentialEmail));
        lifecycle.advance();
      } else {
        // Return portal should show modal, others navigate to pge
        if(runtime === AppRuntimes.returnPortal) {
          setDisplayEmailModal(true);
        } else {
          dispatch(goToPage("missingReturnEmail"));
        }
      }
    }
  }

  const shouldRenderReturnShoppingPreview = () => {
    return !isEmpty(returnShoppingItems) && !!returnShoppingEnabled;
  };

  const shouldRenderReturnShoppingFeeWaiverCopy = () => {
    if ((tallies.processingFeeStatus === ProcessingFeeStatus.WaivedDisplay ||
        tallies.processingFeeStatus === ProcessingFeeStatus.WaivedReadonlyDisplay) &&
      !!parseFloat(tallies.adjustments[processingFeeKey])) {
      return true;
    }
    return false;
  };

  //----------------------------------------------------------------------------
  // RENDER METHODS

  // exchange items are the only return type that requires special logic so we've made a
  // dedicated render function and an "everything else" render function for the other return types
  const renderExchangeItems = () => {
    const priceDisplay = runtime === AppRuntimes.returnPortal? "inline" : undefined;
    const thumbnailClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ProductImage
      })
    }
    return (
      <WrapIf
        condition={!shouldRenderReturnShoppingPreview()}
        wrapIf={(children) => (
          <$ReturnPreviewCard key="exchange" data-cy={DataCyStrings.returnPreviewCard}>
            {children}
          </$ReturnPreviewCard>
        )}
        wrapElse={(children) => (
          <div key="exchange" className="exchange-preview-card">
            {children}
          </div>
        )}
      >
        <div className="return-type" data-cy={`${DataCyStrings.returnType}-exchange`}>
          <h2>{t("exchange")}</h2>
        </div>

        {sortedReturnItems.exchange.map((item, idx) => {
          return (
            <div
              key={item.id}
              className={`exchange-row ${idx === 0 ? "first" : ""}`}
              data-cy={DataCyStrings.exchangeRow}
            >
              <div className="returning">
                <div className={`returning-row ${idx != 0 ? "hide-on-desktop" : ""}`}><h3>{t("returning")}</h3></div>
                <ItemDetails
                  item={item}
                  showThumbnail
                  priceDisplay={priceDisplay}
                  runtime={runtime}
                  dataCyString={DataCyStrings.returningItemInfo}
                  onClickForThumbnail={thumbnailClick}
                  hidePrice={!checkIfItemHasValidPrice(item)}
                />
              </div>
              <div className="getting">
                <div className={`getting-row ${idx != 0 ? "hide-on-desktop" : ""}`}><h3>{t("getting")}</h3></div>
                <ItemDetails
                  item={item.refund.exchange}
                  showThumbnail
                  priceDisplay={priceDisplay}
                  runtime={runtime}
                  dataCyString={DataCyStrings.gettingItemInfo}
                  onClickForThumbnail={thumbnailClick}
                  hidePrice={!checkIfItemHasValidPrice(item.refund.exchange)}
                />
              </div>
            </div>
          );
        })}
      </WrapIf>
    );
  }

  const renderPreviewCard = (returnType, returnTypeLabel) => {
    let priceDisplay: "right" | "inline" | undefined = shouldShowItemPrices() ? "right" : undefined;
    if (priceDisplay && runtime === AppRuntimes.returnPortal)
      priceDisplay = shouldRenderReturnShoppingPreview() ? "right" : "inline";
    const thumbnailClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ProductImage
      })
    }

    return (
      <WrapIf
        condition={!shouldRenderReturnShoppingPreview()}
        wrapIf={(children) => (
          <$ReturnPreviewCard key={returnType} data-cy={DataCyStrings.returnPreviewCard}>
            {children}
          </$ReturnPreviewCard>
        )}
        wrapElse={(children) => (
          <div key={returnType} className="return-preview-card">
            {children}
          </div>
        )}
      >
        <div className="return-type" data-cy={`${DataCyStrings.returnType}-${returnType}`}>
          <h2>{returnTypeLabel}</h2>
        </div>
        {!shouldRenderReturnShoppingPreview() && <$ScreenReaderOnlyComponent as="h3">{t("returningLowerCase")}</$ScreenReaderOnlyComponent>}
        {shouldRenderReturnShoppingPreview() && <div className="returning-refund"><h3>{t("returning")}</h3></div>}
        {sortedReturnItems[returnType].map((item) => {
          return <ItemDetails key={item.id} item={item} showThumbnail runtime={runtime} priceDisplay={priceDisplay} onClickForThumbnail={thumbnailClick} hidePrice={!checkIfItemHasValidPrice(item)}/>;
        })}
      </WrapIf>
    );
  }

  const renderAdjustments = () => {
    // Processing fee needs to be the last item displayed in adjustments. Adjustments object is converted
    // to an array and initially processing fee is placed first, then order is reversed.
    // Also, checks if Taxes are included in the price - if true, remove them from the list.
    const adjustments: [string, string][] = [];
    for (const [k, v] of Object.entries(tallies.adjustments || {})) {
      if (k === processingFeeKey) {
        adjustments.unshift([k, v]);
      } else if (tallies.isTaxIncluded && k === taxKey) {
        delete adjustments[adjustments.indexOf([k, v])];
      } else {
        adjustments.push([k, v]);
      }
    }
    adjustments.reverse();

    const onInfoIconClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingInfoIcon
      })
    }
    return (
      <>
        {adjustments.map(([key, value]) => {
            let lineThrough = false;
            const renamedProcessingKey = t("returnProcessingFee");
            // processing fee statuses 1 & 3 are meant to display waived fees with a line through.

            if (key === processingFeeKey && (tallies.processingFeeStatus === ProcessingFeeStatus.WaivedDisplay || tallies.processingFeeStatus === ProcessingFeeStatus.WaivedReadonlyDisplay)) {
              lineThrough = true;
            }

            return (
              <div key={key} className={"row " + (lineThrough ? "line-through" : "")}>
                <div className="line-item">
                  {tallies?.adjustmentsLocalized?.[key]?.key ? tallies?.adjustmentsLocalized?.[key]?.key : (key === processingFeeKey ? renamedProcessingKey : key)}
                  {lineThrough && <div onClick={onInfoIconClick}><SVG name="info-black" /></div>}
                </div>
                <div data-cy={generateDataCyString(key)}>
                  {tallies?.adjustmentsLocalized?.[key]?.value || value}
                </div>
              </div>
            );
          })
        }
      </>
    );
  };

  const renderTallies = () => {
    // don't display container if there are no distributions/adjustments to display
    if(isEmpty(tallies.adjustments) && isEmpty(tallies.distributions)) {
      return;
    }

    if(!tallies.isTotalValid && !tallies.isSubTotalValid && !tallies.showAdjustments && !tallies.showDistributions) {
      return;
    }

    // if items are not allowed to display price, hide refund totals as well
    if (!shouldShowItemPrices()) {
      return;
    }

    return (
      <WrapIf
        condition={!shouldRenderReturnShoppingPreview()}
        wrapIf={(children) => (
          <$ReturnPreviewCard key="totals" data-cy={DataCyStrings.returnPreviewCard}>
            {children}
          </$ReturnPreviewCard>
        )}
        wrapElse={(children) => (
          <div key="totals">
            <div className="summary"><h3>{t("summary")}</h3></div>
            {children}
          </div>
        )}
      >
        <$ReturnTotal className={runtime ? runtime : undefined}>
          <$ScreenReaderOnlyComponent as="h2">{t("whatYoureGetting")}</$ScreenReaderOnlyComponent>
          <$ScreenReaderOnlyComponent as="h3">{t("refundSummary")}</$ScreenReaderOnlyComponent>
          <div className="row">
            <div>{t("subtotal")}</div>
            <div data-cy={DataCyStrings.subtotalAmount}>{tallies.subtotal}</div>
          </div>

          {renderAdjustments()}

          {tallies.total && (
            <div className="row highlighted">
              <div>
                <b>{t("total")}</b>
              </div>
              <div data-cy={DataCyStrings.totalAmount}>{tallies.total}</div>
            </div>
          )}
          {!shouldRenderReturnShoppingPreview() && renderDistributions()}
        </$ReturnTotal>
      </WrapIf>
    );
  }

  const renderDistributions = () => {
    return(
      <>
      {!isEmpty(tallies.distributions) && (
        <div className="refunding-to">
          <div><h3>{t("refundingTo")}</h3></div>
          <div />
        </div>
      )}
      {!isEmpty(tallies.distributions) &&
      Object.entries(tallies.distributions).map(([key, value]) => {
        return (
          <div key={key} className="row distribution" data-cy={DataCyStrings.distributionRow}>
            <div>{tallies?.distributionsLocalized?.[key]?.key || key}</div>
            <div data-cy={key ? generateDataCyString(key) : DataCyStrings.originalPaymentAmount}>
              {tallies?.distributionsLocalized?.[key]?.value || value}
            </div>
          </div>
        );
      })}
      </>
    );
  }

  const renderReturnShoppingPreviewCard = () => {
    const thumbnailClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingProductImage
      })
    }
    const images = returnShoppingItems.map((item) => (
      // blank image URL if image isn't available
      // better than a broken image
      <img src={item.image || "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="} alt={item.title} className="return-shopping-images" data-cy={DataCyStrings.returnShoppingImages} onClick={thumbnailClick}/>
    ))
    const numItemsInCart = returnShoppingItems.reduce(function(result, item) {
      return result + item.quantity;
    }, 0);
    const onReturnShoppingDiscountClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingDiscount
      })
    }
    const onMoreIconClick = () => {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingMoreIcon
      })
    }
    return (
      <$ReturnShoppingPreviewCard
        data-cy={DataCyStrings.returnShoppingPreviewCard}
      >
          <div className="return-type in-your-cart" >
            <div className="in-your-cart-header" data-cy={DataCyStrings.inYourCartHeader}><h2>{numItemsInCart > 1 ? t("inYourCartMultiple",
              { numItems:  numItemsInCart}) : t("inYourCartSingle")}</h2></div>
            <DetailsToggle active={inYourCartVisible} onClick={toggleInYourCartVisibleState}/>
          </div>
        {inYourCartVisible && (
          <>
            <div className="in-your-cart-divider"></div>
            <div className="return-shopping-header"><h3>{t("theseItemsAreWaiting")}</h3></div>
            <div className="return-shopping-image-container">
              {images.slice(0, 3)}
              {images.length > 3 && (
                <div className="more" data-cy={DataCyStrings.moreIcon} onClick={onMoreIconClick}>
                  {"+\n"}
                  {t("more")}
                </div>
              )}
            </div>
          </>
          )}
        {!inYourCartVisible && !!returnShoppingDiscount && (
        <div className="in-your-cart-divider"></div>
        )}
        {!!returnShoppingDiscount && (
          <div className="return-shopping-discount" data-cy={DataCyStrings.returnShoppingDiscount} onClick={onReturnShoppingDiscountClick}>
            <div className="icon">
              <SVG name="tag" />
            </div>
            {t("discountIsApplied", { discount: returnShoppingDiscount })}
          </div>
        )}
      </$ReturnShoppingPreviewCard>
    );
  };

  const baseComponentsByRuntime = {
    "return-portal": PreviewReturnPortalWrapper
  }
  const BaseComponent = baseComponentsByRuntime[app?.runtime] ?
  baseComponentsByRuntime[app.runtime] :
  $PreviewContentBox

  const advanceByRuntime = {
    "return-portal": () => onSubmitReturnClicked()
  }
  const advance = advanceByRuntime[app?.runtime] ? advanceByRuntime[app.runtime] : () => lifecycle.advance();
  const numReturnItems = sortedReturnItems.refund.length + sortedReturnItems.storeCredit.length + sortedReturnItems.exchange.length;
  const toggleRefundVisibleState = () => {
    if (refundVisible) {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingShowLessInYourReturn
      })
    } else {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingShowMoreInYourReturn
      })
    }
    setRefundVisible(!refundVisible);
  }
  const toggleInYourCartVisibleState = () => {
    if (inYourCartVisible) {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingShowLessInYourCart
      })
    } else {
      ga.event({
        category: AnalyticCategories.PreviewPage,
        action: PreviewPageActions.ReturnShoppingShowMoreInYourCart
      })
    }
    setInYourCartVisible(!inYourCartVisible);
  }

  const onCustomMessageClick = () => {
    ga.event({
      category: AnalyticCategories.PreviewPage,
      action: PreviewPageActions.CustomText
    })
  }

  const onClickReturnShoppingSelectedDropoffMethod = () => {
    ga.event({
      category: AnalyticCategories.PreviewPage,
      action: PreviewPageActions.ReturnShoppingReturnMethod
    })
  }

  if (error) {
    return (
      <BaseComponent className={runtime ? runtime : undefined} page={page} advance={advance} isFooterDisabledCallback={() => isSubmitReturnButtonDisabled()}>
        <ErrorAlertMessage message={error} />
      </BaseComponent>
    );
  }

  //----------------------------------------------------------------------------
  return (
    <BaseComponent className={runtime} page={page} advance={advance} footer={footer}>
      {copies?.previewInfoMessage && (
        <div className="banner return-preview-custom-message" onClick={onCustomMessageClick}>
          <InfoMessage message={copies.previewInfoMessage} />
        </div>
      )}
      {runtime != "return-portal" && !loadingMessage &&
        <div className="banner">
          <InfoMessage message="Keep all footwear in the original shoe box, when provided." data-cy="footwear-message" />
        </div>
      }
      <div className="list">
        {!loadingMessage &&
          !areItemsEmpty() &&
          runtime != AppRuntimes.returnista &&
          runtime != AppRuntimes.returnPortal && (
            <div className="header" data-cy={DataCyStrings.previewPageHeader}>
              {t("confirmYouveReceived")}
            </div>
          )}
        <WrapIf
          condition={shouldRenderReturnShoppingPreview()}
          wrapIf={(children) => (
            <>

              <$ReturnPreviewCard data-cy={DataCyStrings.returnPreviewCard} className="return-shopping-wrapper">
                <div className="refund-block"><div className="refund-header" data-cy={DataCyStrings.refundHeader}><h2>{numReturnItems > 1 ? t("inYourReturnMultiple",
                  { numItems:  numReturnItems}) : t("inYourReturnSingle")}</h2></div>
                  <DetailsToggle active={refundVisible} onClick={toggleRefundVisibleState}/>
                </div>
                {refundVisible && (
                  children
                )}
                {refundVisible && shouldRenderReturnShoppingFeeWaiverCopy() && (
                  <div className="disclaimerBlock">
                    <div className="disclaimer">
                      <div className="icon">
                        <SVG name="info-black" />
                      </div>
                      <div data-cy={DataCyStrings.returnShoppingWaiverText}>
                        {`${t("returnProcessingFeeWaivedAfterNewOrder")}`}
                      </div>
                    </div>
                  </div>
                )}
                {renderDistributions()}
                <div className="return-method">
                  <div className="label"><h3>{t("returnMethod")}</h3></div>
                  <div className="dropoff-method" data-cy={DataCyStrings.dropoffMethodText} onClick={onClickReturnShoppingSelectedDropoffMethod}>{selectedDropoffMethod?.header}</div>
                </div>

              </$ReturnPreviewCard>

              {renderReturnShoppingPreviewCard()}
            </>
          )}
          wrapElse={(children) => <>{children}</>}
        >
          <>
            {sortedReturnItems.exchange.length !== 0 && renderExchangeItems()}
            {sortedReturnItems.storeCredit.length !== 0 &&
              renderPreviewCard("storeCredit", app?.copies?.storeCreditLabel || t("giftCard"))}
            {sortedReturnItems.refund.length !== 0 && renderPreviewCard("refund", t("refund"))}
            {renderTallies()}
          </>
        </WrapIf>
      </div>
      {!loadingMessage && !areItemsEmpty() && runtime != AppRuntimes.returnPortal && (
        <ReturnCountFooter count={itemsMarkedForCompletion.length} runtime={runtime}>
          <PrimaryButton
            onButtonClick={() => {
              onSubmitReturnClicked();
            }}
            label={t("submitReturn")}
            width="136px"
            dataCyString={DataCyStrings.submitReturnButton}
          />
        </ReturnCountFooter>
      )}
      <EmailNeededModal
        isOpen={displayEmailModal}
        onClose={() => setDisplayEmailModal(false)}
        onSuccess={onEmailModalSuccess}
      />
    </BaseComponent>
  );
}

const PreviewReturnPortalWrapper = ({ page, children, advance, isFooterDisabledCallback, footer }) => {
  return (
    <ReturnPortalBaseWithPage
      page={page}
      advance={advance}
      isFooterDisabledCallback={isFooterDisabledCallback}
      footer={footer}
    >
      {children}
    </ReturnPortalBaseWithPage>
  );
}

const DetailsToggle = ({ active, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="toggle-details" onClick={onClick} data-cy={DataCyStrings.showMoreLink}>
      {active ? t('showLess') : t('showMore')}<SVG name={ active ? "up" : "down" }/>
    </div>
  )
}
export default Preview;
